<template>
  <div class="animated fadeIn">
  </div>
</template>

<script>

  import { indexApi } from '@/service'

export default {
  name: 'dashboard',

  data() {
    return {
      loading: true,
    }
  },

  mounted : async function () {
    await this.$store.dispatch('app/changePage', { title:'Dashboard', size: 3 })
  }
}
</script>

<style>

</style>
